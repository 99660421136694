#home{
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  min-height: 100vh;
  .logout-wrapper{
    position: fixed;
    top: 0;
    right: 0;
    font-size: 2rem;
    margin: 1rem 2rem;
    @include md{
      font-size: 1.5rem;
    }
    a{
      color: #d26262;
    }
  }
  .home-list{
    display: flex;
    flex-direction: column;
    margin: 2rem 0;
    @include ipad-yoko{
      margin: 1rem 0;
    }
    .list-title{
      display: flex;
      align-items: center;
      margin-bottom: 1.25rem;
      @include ipad-yoko{
        margin-bottom: 0.75rem;
      }
      h2{
        width: 50%;
        text-align: center;
        font-family: sans-serif;
        font-size: 2rem;
        font-weight: bold;
        @include sm{
          font-size: 1.5rem;
        }
        span{
          padding-bottom: 0.25rem;
        }
        span.daiko{
          border-bottom: 2px solid #3047BC;
        }
        span.toyo{
          border-bottom: 2px solid #FF5126;
        }
      }
      hr{
        background: #777;
        width: 2px;
        height: 64px;
        border-radius: 1px;
        margin: 0;
        margin-bottom: 0.5rem;
      }
    }
    .list-col{
      display: flex;
      justify-content: center;
      align-items: flex-start;
      .home-item{
        margin: 0 1.25rem 0.75rem;
        @include ipad-yoko{
          margin:  0 1rem 0.5rem;
        }
        @include sm{
          margin: 0 0.3rem 0.5rem;
        }
        a{
          display: flex;
          justify-content: center;
          align-items: center;
          background: #979797;
          color: #fff;
          text-align: center;
          text-decoration: none;
          font-size: 3.5rem;
          width: 140px;
          height: 140px;
          border-radius: 50%;
          padding: 0.25rem;
          margin: auto;
          margin-bottom: 0.5rem;
          transition: 0.5s;
          box-shadow: 1px 4px 2px rgba(10,10,10,0.4);
          @include ipad-yoko{
            width: 120px;
            height: 120px;
          }
          @include sm{
            width: 120px;
            height: 120px;
          }
          @include xs{
            font-size: 2rem;
            width: 84px;
            height: 84px;
          }
        }
        a:hover{
          box-shadow: none;
          transform: translateY(2px);
        }
        a.bg-daiko{
          background: #3047BC;
        }
        a.bg-common{
          background: #2FC885;
        }
        a.bg-toyo{
          background: #FF5126;
        }
        p{
          text-align: center;
          font-size: 1rem;
          font-weight: bold;
          width: 140px;
          margin: auto;
          @include sm{
            font-size: 0.75rem;
            width: 84px;
          }
        }
      }
    }
  }
}
