.leaflet-container {
  height: 100vh;
  width: 100%;
}

.leaflet-popup-content {
  font-size: 16px;
  line-height: 2;
}

.leaflet-marker-icon.marker-id {
  font-size: 15px;
  color: white;
  padding-left: 20px;

  div {
    width: 6rem;
  }
}
