.panorama {
  .next {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    font-size: 2.5rem;
    top: 50vh;
    right: 4px;
    width: 3rem;
    height: 3rem;
    text-align: center;
    color: #fff;
    @include sm{
      font-size: 1.5rem;
      width: 2.5rem;
      height: 2.5rem;
    }
  }
  .prev {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    font-size: 2.5rem;
    top: 50vh;
    left: 4px;
    width: 3rem;
    height: 3rem;
    text-align: center;
    color: #fff;
    @include sm{
      font-size: 1.5rem;
      width: 2.5rem;
      height: 2.5rem;
    }
  }
  .id {
    position: fixed;
    bottom: 0;
    padding: 5px 10px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.3);
    color: #ccc;
  }
}
