html {
  min-height: 100%;
  position: relative;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'TsukuARdGothic-Regular',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f6f5f0;
  color: #2c2c2c;
  &.ReactModal__Body--open {
    overflow: hidden;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@import 'mixin.scss';
@import 'modal.scss';
@import 'common.scss';
@import 'home.scss';
@import 'signin.scss';
@import 'leaflet.scss';
@import 'panorama.scss';
